import { createSlice } from "@reduxjs/toolkit"

const initialState = {
   lang: false,
   modalent: false,
   modalreg: false,
   modalpass: false,
   getmodalpass: false,
   truegetmodalpass: false,
   newpassword: false,
   video: false
}

export const langvichSlice = createSlice({
   name: 'langvich',
   initialState,
   reducers: {
      english: (state) => {
         state.lang = false
      },

      russian: (state) => {
         state.lang = true
      },

      truemodalent: (state) => {
         state.modalent = true
      },

      falsemodalent: (state) => {
         state.modalent = false
      },

      truemodalreg: (state) => {
         state.modalreg = true
      },

      falsemodalreg: (state) => {
         state.modalreg = false
      },

      truemodalpass: (state) => {
         state.modalpass = true
      },

      falsemodalpass: (state) => {
         state.modalpass = false
      },
      playvideo: (state, action) => {
         state.video = action.payload
      },

      trueGetModalPass: (state, action) => {
         state.getmodalpass = action.payload
      },

      GetModalPassFuncTrue: (state, action) => {
         state.truegetmodalpass = action.payload
      },

      newpasswordfunc: (state, action) => {
         state.newpassword = action.payload
      }
   }
})

export default langvichSlice.reducer