import { configureStore } from "@reduxjs/toolkit"
import { combineReducers } from "redux"
import langvichReduser from "./reduser"

const rootReduser = combineReducers({
   langvichReduser
})

export const setupStore = () => {
   return configureStore({
      reducer: rootReduser
   })
}