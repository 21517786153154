import React from "react";
// import { Route, Routes } from "react-router-dom/dist";
// import MainLanding from "./components/mainLanding/MainLanding";
// import NewPasswordPage from "./components/newPasswordPage/NewPasswordPage";
// import NumberTokens from "./components/numberTokens/NumberTokens";

function App() {
  return (
    // <Routes>
    //   <Route path="/" element={<MainLanding />} />
    //   <Route path="/data" element={<NumberTokens />} />
    //   <Route path="/restore/:id" element={<NewPasswordPage />} />
    // </Routes>
      <div style={{textAlign: "center", fontSize: '26px', marginTop: '20%'}}>В системе ведутся технические работы</div>
  );
}

export default App;
